<template>

<div class="group">

	<com-banner :banner="category.banner" />

	<com-items :group="group.id" class="group-items" v-if="!isDesktop" />

	<div class="group-content">
		
		<app-content v-for="item in group.texts" :key="item" :id="item" />

	</div>

	<com-location />

</div>

</template>

<script>

import comBanner from './common/Banner'
import comItems from './group/Items'
import comLocation from './group/Location'

export default {

	components: {
		'com-banner': comBanner,
		'com-items': comItems,
		'com-location': comLocation
	},

	created: function() {
	
		if (process.env.VUE_APP_TYPE !== 'preview') this.$api.post('view', {
			group: this.$store.getters['group'],
			location: 0
		})
	
	},

	computed: {

		group: function() {

			return this.$_.findWhere(this.$store.getters['groups'], {
				id: this.$store.getters['group']
			})

		},

		category: function() {

			return this.$_.findWhere(this.$store.getters['categories'], {
				id: this.group.category
			})

		}

	}

}

</script>

<style scoped>

.group {
	padding: 20px 10px;
}

.is-desktop .group {
	padding-top: 0px;
}

.is-tablet .group {
	display: flex;
	flex-direction: row;
}

.is-tablet .group-items {
	width: 320px;
	margin-right: 40px;
	flex-shrink: 0;
}

.is-tablet .group-content {
	flex-grow: 1;
}

.is-tablet .group-content >>> .content:first-child {
	margin-top: 0px;
}

.category-content-image {
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}

</style>
